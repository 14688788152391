// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
// require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("packs/script")
import 'bootstrap';

window.jQuery = $;
window.$ = $;
document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
	$('form.grid-data-form').bind('change', function (event) {$(event.currentTarget).submit();});  
})

$(document).ready( function () {
  if (document.querySelectorAll('.ctrl-welcome-index').length) import("./welcome/index")
  if (document.querySelectorAll('.ctrl-customer-registration').length){
    import('./customers/registration_steps')
  }
  if (document.querySelectorAll('.register-win').length){
    import('./customers/register_win')
    import('./customers/registration_steps')
  }
});
